<template>
  <div>
    <b-card class="d-flex">
      <h4 style="text-align: center">Cadastrar Modelo Tributário</h4>
      <!-- Modelo -->
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="mesRef">Mês Ref:</label>
          <input
            type="text"
            id="mesRef"
            v-model="mesRef"
            class="form-control"
            v-mask="'##/####'"
          />
        </div>
        <div class="form-group col-lg-6">
          <label for="distribuidora">Distribuidora:</label>
          <b-select
            v-model="selectedDistribuidora"
            :options="distribuidoras"
            value-field="id"
            text-field="nome"
          ></b-select>
        </div>
        <!--
        <div class="form-group col-md-4">
          <label for="unidade">Unidade:</label>
          <b-select
            id="unidade"
            v-model="selectedUnidade"
            :options="unidades"
          ></b-select>
        </div>
        -->
      </div>
      <b-tabs
        pills
        card
        vertical
        size="md"
        class="tabs-container no-left-spacing"
      >
        <!-- Consumo -->
        <b-tab title="Consumo">
          <div class="form-group col-lg-7">
            <label for="CONSUMO_PIS">CONSUMO_PIS:</label>
            <b-select
              id="CONSUMO_PIS"
              v-model="consumoPis"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="CONSUMO_COFINS">CONSUMO_COFINS:</label>
            <b-select
              id="CONSUMO_COFINS"
              v-model="consumoCofins"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="CONSUMO_ICMS">CONSUMO_ICMS:</label>
            <b-select
              id="CONSUMO_ICMS"
              v-model="consumoIcms"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Demanda -->
        <b-tab title="Demanda">
          <div class="form-group col-lg-7">
            <label for="DEMANDA_PIS">DEMANDA_PIS:</label>
            <b-select
              id="DEMANDA_PIS"
              v-model="demandaPis"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DEMANDA_COFINS">DEMANDA_COFINS:</label>
            <b-select
              id="DEMANDA_COFINS"
              v-model="demandaCofins"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DEMANDA_ICMS">DEMANDA_ICMS:</label>
            <b-select
              id="DEMANDA_ICMS"
              v-model="demandaIcms"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Subvenção Demanda -->
        <b-tab title="Subvenção Demanda">
          <div class="form-group col-lg-7">
            <label for="DEMANDA_PIS_SUBV">DEMANDA_PIS_SUBV:</label>
            <b-select
              id="DEMANDA_PIS_SUBV"
              v-model="demandaPisSubv"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DEMANDA_COFINS_SUBV">DEMANDA_COFINS_SUBV:</label>
            <b-select
              id="DEMANDA_COFINS_SUBV"
              v-model="demandaCofinsSubv"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DEMANDA_ICMS_SUBV">DEMANDA_ICMS_SUBV:</label>
            <b-select
              id="DEMANDA_ICMS_SUBV"
              v-model="demandaIcmsSubv"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Demanda Não Utilizada(DNU) -->
        <b-tab title="Demanda Não Utilizada(DNU)">
          <div class="form-group col-lg-7">
            <label for="DNU_PIS">DNU_PIS:</label>
            <b-select
              id="DNU_PIS"
              v-model="dnuPis"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DNU_COFINS">DNU_COFINS:</label>
            <b-select
              id="DNU_COFINS"
              v-model="dnuCofins"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DNU_ICMS">DNU_ICMS:</label>
            <b-select
              id="DNU_ICMS"
              v-model="dnuIcms"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Subvenção Demanda Não Utilizada(DNU) -->
        <b-tab title="Subvenção Demanda Não Utilizada(DNU)">
          <div class="form-group col-lg-7">
            <label for="DNU_PIS_SUBV">DNU_PIS_SUBV:</label>
            <b-select
              id="DNU_PIS_SUBV"
              v-model="dnuPisSubv"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DNU_COFINS_SUBV">DNU_COFINS_SUBV:</label>
            <b-select
              id="DNU_COFINS_SUBV"
              v-model="dnuCofinsSubv"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DNU_ICMS_SUBV">DNU_ICMS_SUBV:</label>
            <b-select
              id="DNU_ICMS_SUBV"
              v-model="dnuIcmsSubv"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Ultrapassagem de Demanda(UD) -->
        <b-tab title="Ultrapassagem de Demanda(UD)">
          <div class="form-group col-lg-7">
            <label for="UD_PIS">UD_PIS:</label>
            <b-select
              id="UD_PIS"
              v-model="udPis"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="UD_COFINS">UD_COFINS:</label>
            <b-select
              id="UD_COFINS"
              v-model="udCofins"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="UD_ICMS">UD_ICMS:</label>
            <b-select
              id="UD_ICMS"
              v-model="udIcms"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- TUDS Encargos -->
        <b-tab title="TUDS Encargos">
          <div class="form-group col-lg-7">
            <label for="TUSD_ENCARGOS_PIS">TUSD_ENCARGOS_PIS:</label>
            <b-select
              id="TUSD_ENCARGOS_PIS"
              v-model="tudsEncargosPis"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="TUSD_ENCARGOS_COFINS">TUSD_ENCARGOS_COFINS:</label>
            <b-select
              id="TUSD_ENCARGOS_COFINS"
              v-model="tudsEncargosCofins"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="TUSD_ENCARGOS_ICMS">TUSD_ENCARGOS_ICMS:</label>
            <b-select
              id="TUSD_ENCARGOS_ICMS"
              v-model="tudsEncargosIcms"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Subvenção TUSD Encargos -->
        <b-tab title="Subvenção TUSD Encargos">
          <div class="form-group col-lg-7">
            <label for="TUSD_ENCARGOS_PIS_SUBV">TUSD_ENCARGOS_PIS_SUBV:</label>
            <b-select
              id="TUSD_ENCARGOS_PIS_SUBV"
              v-model="tudsEncargosPisSubv"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="TUSD_ENCARGOS_COFINS_SUBV"
              >TUSD_ENCARGOS_COFINS_SUBV:</label
            >
            <b-select
              id="TUSD_ENCARGOS_COFINS_SUBV"
              v-model="tudsEncargosCofinsSubv"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="TUSD_ENCARGOS_ICMS_SUBV"
              >TUSD_ENCARGOS_ICMS_SUBV:</label
            >
            <b-select
              id="TUSD_ENCARGOS_ICMS_SUBV"
              v-model="tudsEncargosIcmsSubv"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Energia Reativa Excedente -->
        <b-tab title="Energia Reativa Excedente">
          <div class="form-group col-lg-7">
            <label for="ENRE_PIS">ENRE_PIS:</label>
            <b-select
              id="ENRE_PIS"
              v-model="enrePis"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="ENRE_COFINS">ENRE_COFINS:</label>
            <b-select
              id="ENRE_COFINS"
              v-model="enreCofins"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="ENRE_ICMS">ENRE_ICMS:</label>
            <b-select
              id="ENRE_ICMS"
              v-model="enreIcms"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Demanda Reativa Excedente -->
        <b-tab title="Demanda Reativa Excedente">
          <div class="form-group col-lg-7">
            <label for="DRET_PIS">DRET_PIS:</label>
            <b-select
              id="DRET_PIS"
              v-model="dretPis"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DRET_COFINS">DRET_COFINS:</label>
            <b-select
              id="DRET_COFINS"
              v-model="dretCofins"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="DRET_ICMS">DRET_ICMS:</label>
            <b-select
              id="DRET_ICMS"
              v-model="dretIcms"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Encargo COVID -->
        <b-tab title="Encargo COVID">
          <div class="form-group col-lg-7">
            <label for="COVID_PIS">COVID_PIS:</label>
            <b-select
              id="COVID_PIS"
              v-model="covidPis"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="COVID_COFINS">COVID_COFINS:</label>
            <b-select
              id="COVID_COFINS"
              v-model="covidCofins"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="COVID_ICMS">COVID_ICMS:</label>
            <b-select
              id="COVID_ICMS"
              v-model="covidIcms"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>

        <!-- Encargo Escassez Hídrica -->
        <b-tab title="Encargo Escassez Hídrica">
          <div class="form-group col-lg-7">
            <label for="ESCASSEZ_HIDRICA_PIS">ESCASSEZ_HIDRICA_PIS:</label>
            <b-select
              id="ESCASSEZ_HIDRICA_PIS"
              v-model="escassezHidricaPis"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="ESCASSEZ_HIDRICA_COFINS"
              >ESCASSEZ_HIDRICA_COFINS:</label
            >
            <b-select
              id="ESCASSEZ_HIDRICA_COFINS"
              v-model="escassezHidricaCofins"
              :options="tributariosOptions"
            ></b-select>
          </div>
          <div class="form-group col-lg-7">
            <label for="ESCASSEZ_HIDRICA_ICMS">ESCASSEZ_HIDRICA_ICMS:</label>
            <b-select
              id="ESCASSEZ_HIDRICA_ICMS"
              v-model="escassezHidricaIcms"
              :options="tributariosOptions"
            ></b-select>
          </div>
        </b-tab>
      </b-tabs>
      <div class="d-flex justify-content-end mt-3">
        <!-- Show the "Salvar" button if `data` is null -->
        <b-button v-if="!modoEditar" variant="success" @click="save"
          >Salvar</b-button
        >

        <!-- Show the "Atualizar" button if `data` is not null -->
        <b-button v-else variant="primary" @click="update">Atualizar</b-button>

        <!-- "Remover" button -->
        <b-button variant="danger" class="ml-2" @click="remove"
          >Remover</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modoEditar: false,
      mesRef: '',
      selectedUnidade: '',
      distribuidoras: [],
      selectedDistribuidora: null,
      consumoPis: '',
      consumoCofins: '',
      consumoIcms: '',
      demandaPis: '',
      demandaCofins: '',
      demandaIcms: '',
      demandaPisSubv: '',
      demandaCofinsSubv: '',
      demandaIcmsSubv: '',
      dnuPis: '',
      dnuCofins: '',
      dnuIcms: '',
      dnuPisSubv: '',
      dnuCofinsSubv: '',
      dnuIcmsSubv: '',
      udPis: '',
      udCofins: '',
      udIcms: '',
      tudsEncargosPis: '',
      tudsEncargosCofins: '',
      tudsEncargosIcms: '',
      tudsEncargosPisSubv: '',
      tudsEncargosCofinsSubv: '',
      tudsEncargosIcmsSubv: '',
      enrePis: '',
      enreCofins: '',
      enreIcms: '',
      dretPis: '',
      dretCofins: '',
      dretIcms: '',
      covidPis: '',
      covidCofins: '',
      covidIcms: '',
      escassezHidricaPis: '',
      escassezHidricaCofins: '',
      escassezHidricaIcms: '',
      tributariosOptions: [
        'NA',
        'FPC',
        'FICMS',
        'FTRIBT_AC',
        'FTRIBT_NA',
        'F_ENEL',
        'F_COELBA_NA'
      ]
    };
  },
  created() {
    const modelos = JSON.parse(this.$route.query.modelos);
    if (this.$route.query.modelos) {
      if (modelos && modelos.length) {
        try {
          this.distribuidoras = modelos.map((modelo) => {
            return {
              id: modelo.distribuidora.id,
              nome: modelo.distribuidora.nomeDistribuidora
            };
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    const data = JSON.parse(this.$route.query.modelo);
    if (this.$route.query.modelo) {
      this.modoEditar = true;
      if (data) {
        try {
          this.mesRef = data.mesRef;
          this.selectedDistribuidora = data.distribuidora.id;
          const modelos = data.modelosTributarios;

          if (modelos) {
            this.consumoPis = modelos.modeloTributarioConsumo.modeloPis;
            this.consumoCofins = modelos.modeloTributarioConsumo.modeloCofins;
            this.consumoIcms = modelos.modeloTributarioConsumo.modeloIcms;

            this.demandaPis = modelos.modeloTributarioDemanda.modeloPis;
            this.demandaCofins = modelos.modeloTributarioDemanda.modeloCofins;
            this.demandaIcms = modelos.modeloTributarioDemanda.modeloIcms;

            this.demandaPisSubv = modelos.modeloTributarioDemandaSubv.modeloPis;
            this.demandaCofinsSubv =
              modelos.modeloTributarioDemandaSubv.modeloCofins;
            this.demandaIcmsSubv =
              modelos.modeloTributarioDemandaSubv.modeloIcms;

            this.dnuPis = modelos.modeloTributarioDemandaNaoUtilizada.modeloPis;
            this.dnuCofins =
              modelos.modeloTributarioDemandaNaoUtilizada.modeloCofins;
            this.dnuIcms =
              modelos.modeloTributarioDemandaNaoUtilizada.modeloIcms;

            this.dnuPisSubv =
              modelos.modeloTributarioDemandaNaoUtilizadaSubv.modeloPis;
            this.dnuCofinsSubv =
              modelos.modeloTributarioDemandaNaoUtilizadaSubv.modeloCofins;
            this.dnuIcmsSubv =
              modelos.modeloTributarioDemandaNaoUtilizadaSubv.modeloIcms;

            this.udPis = modelos.modeloTributarioUltrapassagemDemanda.modeloPis;
            this.udCofins =
              modelos.modeloTributarioUltrapassagemDemanda.modeloCofins;
            this.udIcms =
              modelos.modeloTributarioUltrapassagemDemanda.modeloIcms;

            this.tudsEncargosPis =
              modelos.modeloTributarioTUSDEncargos.modeloPis;
            this.tudsEncargosCofins =
              modelos.modeloTributarioTUSDEncargos.modeloCofins;
            this.tudsEncargosIcms =
              modelos.modeloTributarioTUSDEncargos.modeloIcms;

            this.tudsEncargosPisSubv =
              modelos.modeloTributarioTUSDEncargosSubv.modeloPis;
            this.tudsEncargosCofinsSubv =
              modelos.modeloTributarioTUSDEncargosSubv.modeloCofins;
            this.tudsEncargosIcmsSubv =
              modelos.modeloTributarioTUSDEncargosSubv.modeloIcms;

            this.enrePis =
              modelos.modeloTributarioEnergiaReativaExcedente.modeloPis;
            this.enreCofins =
              modelos.modeloTributarioEnergiaReativaExcedente.modeloCofins;
            this.enreIcms =
              modelos.modeloTributarioEnergiaReativaExcedente.modeloIcms;

            this.dretPis =
              modelos.modeloTributarioDemandaReativaExcedenteTotal.modeloPis;
            this.dretCofins =
              modelos.modeloTributarioDemandaReativaExcedenteTotal.modeloCofins;
            this.dretIcms =
              modelos.modeloTributarioDemandaReativaExcedenteTotal.modeloIcms;

            this.covidPis = modelos.modeloCovid.modeloPis;
            this.covidCofins = modelos.modeloCovid.modeloCofins;
            this.covidIcms = modelos.modeloCovid.modeloIcms;

            this.escassezHidricaPis = modelos.modeloEscassezHidrica
              ? modelos.modeloEscassezHidrica.modeloPis
              : '';
            this.escassezHidricaCofins = modelos.modeloEscassezHidrica
              ? modelos.modeloEscassezHidrica.modeloCofins
              : '';
            this.escassezHidricaIcms = modelos.modeloEscassezHidrica
              ? modelos.modeloEscassezHidrica.modeloIcms
              : '';
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  },
  methods: {
    save() {
      // Logic to save the data
      console.log('Saved!');
    },
    update() {
      // Logic to update the data
      console.log('Updated!');
    },
    remove() {
      // Logic to remove the data
      console.log('Removed!');
    }
  }
};
</script>

<style>
.form-group {
  margin-bottom: 20px;
}
</style>
